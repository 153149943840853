import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import Changelog from '@twilio-paste/design-tokens/CHANGELOG.md';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { Link } from 'gatsby';
export const pageQuery = graphql`
  {
    allPasteDesignTokensPackage(filter: {name: {eq: "@twilio-paste/design-tokens"}}) {
      edges {
        node {
          name
          description
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/tokens/design-tokens-package/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Design Tokens package" categoryRoute={SidebarCategoryRoutes.TOKENS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/button" data={props.data.allPasteDesignTokensPackage.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <p>{`The Paste design tokens are available as a NPM package which acts as the central source of truth for Paste. By using this package correctly, you can rely on a well-maintained, up-to-date definition of Twilio's Design Language. A simple way to grab or view the latest files in this package is to visit `}<a parentName="p" {...{
            "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/"
          }}>{`UNPKG - @twilio-paste/design-tokens`}</a>{`.`}</p>
        <h2>{`Supported Formats`}</h2>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    While the Paste Tokens Package supports the following formats, our roadmap{' '}
    <strong>currently prioritizes React Components and Sketch Libraries</strong> as primary deliverables of the Design
    System. Reach out to us at <em>#help-design-system</em> if you'd like to use Paste for another format: we'll help
    you out as much as we can!
  </CalloutText>
        </Callout>
        <p>{`Paste Design Tokens are supported in the following formats within this package:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.json"
            }}>{`JSON`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.less"
            }}>{`Less`}</a></li>
          <li parentName="ul">{`SCSS`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.default.scss"
                }}>{`as individual variables`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.map.scss"
                }}>{`as a SCSS map`}</a></li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.custom-properties.css"
            }}>{`CSS custom properties`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.android.xml"
            }}>{`Android XML`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.ios.json"
            }}>{`iOS JSON`}</a></li>
          <li parentName="ul">{`JavaScript`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.common.js"
                }}>{`commonJS`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.es6.js"
                }}>{`es2015`}</a></li>
            </ul></li>
          <li parentName="ul">{`Typescript definitions`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.common.d.ts"
                }}>{`commonJS`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/tokens.es6.d.ts"
                }}>{`es2015`}</a></li>
            </ul></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/sketch.sketchpalette"
            }}>{`Sketch Palette`}</a></li>
        </ul>
        <h2>{`Themes`}</h2>
        <p>{`Paste supports multiple themes. This is done by exporting the same token formats for each theme. Each file in the root of the design tokens package is exported for each theme with the corresponding values updated for each token. This allows different parts of Twilio to have their own visual styles whilst still maintaining a consistent Twilio feel, coherent component behavior, and preventing Design System bloat. Here's an example of Themes:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Paste (default) theme`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Console theme`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`SendGrid theme`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`\\$color-brand-highlight: #F22F46`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`\\$color-brand-highlight: #F22F46`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`\\$color-brand-highlight: #3368FA`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`\\$font-weight-semibold: 600`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`\\$font-weight-semibold: 500`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`\\$font-weight-semibold: 600`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`See our list of Tokens and their corresponding values in the `}<Link to="/tokens/" mdxType="Link">{`Token List`}</Link>{` page.`}</p>
        <p>{`Within themes, we presently have support for:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/"
            }}>{`Paste (Default)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/themes/console/"
            }}>{`Console`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://unpkg.com/browse/@twilio-paste/design-tokens/dist/themes/sendgrid/"
            }}>{`SendGrid`}</a></li>
        </ul>
        <h2>{`Give us Feedback on this Page`}</h2>
        <p>{`As you use Paste, you'll likely encounter things that `}<em parentName="p">{`don't seem right`}</em>{`. Please reach out with your feedback! Here's some prompts to consider:`}</p>
        <ul>
          <li parentName="ul">{`Is this page easy for me to consume?`}</li>
          <li parentName="ul">{`Is the information supporting it sufficient / well-described?`}</li>
          <li parentName="ul">{`What information is missing?`}</li>
          <li parentName="ul">{`How approachable is the documentation? Can a new engineer / PM / designer at Twilio get started with it easily?`}</li>
        </ul>
        <hr></hr>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      